.fc .fc-scrollgrid-section-sticky > *,
.fc .fc-list-sticky .fc-list-day > * {
  background-color: #fafafa;
}

.fc .fc-list-event:hover td {
  background-color: #5882b2;
  cursor: pointer;
}

.fc .fc-scrollgrid,
.fc .fc-list-table,
.fc .fc-scrollgrid-section .fc-scrollgrid-section-header > * {
  background-color: #fafafa;
  border-radius: 0.22rem;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin: 1em 0;
}

.fc a {
  color:#5882b2;
}

.fc .fc-v-event {
  background-color: #5882b2;
  color: #5882b2;
}