.dont-show-if-empty:empty {
  display: none;
}

[data-theme="dark"] {
  background-color: #0b0e13 !important;
  color: #ddd; }

[data-theme="dark"] hr {
  border: 1px solid #444 !important; }

[data-theme="dark"] pre {
  color: #ddd !important; }

[data-theme="dark"] pre code {
  color: #ddd !important; }

[data-theme="dark"] .text-dark {
  color: #ddd !important; }

[data-theme="dark"] .text-black-50 {
  color: #bbb !important; }

[data-theme="dark"] .form-control {
  background-color: #1a202c !important;
  color: #ddd !important; }

[data-theme="dark"] .form-control:focus {
  background-color: #1a202c !important;
  color: #aaa; }

[data-theme="dark"] .tab-content {
  background-color: #1a202c !important;
  color: #ddd;
  border-color: #444; }

[data-theme="dark"] .css-yk16xz-control {
  background-color: #1a202c !important; }

[data-theme="dark"] .css-1fhf3k1-control {
  background-color: #1a202c !important;
  color: #ddd; }

[data-theme="dark"] .css-26l3qy-menu {
  color: #0b0e13 !important; }

[data-theme="dark"] .css-1uccc91-singleValue {
  background-color: #1a202c !important;
  color: #ddd; }

[data-theme="dark"] .fc .fc-scrollgrid-section-sticky > *,
[data-theme="dark"] .fc .fc-list-sticky .fc-list-day > * {
  background-color: #0b0e13; }

[data-theme="dark"] .fc .fc-scrollgrid,
[data-theme="dark"] .fc .fc-list-table,
[data-theme="dark"] .fc .fc-scrollgrid-section .fc-scrollgrid-section-header > * {
  background-color: #1a202c;
  border-radius: 0.22rem; }

[data-theme="dark"] .animated-hover:hover {
  background-color: #11151d !important; }

[data-theme="dark"] .animated-border-on-hover:hover {
  border-color: #414348; }

[data-theme="dark"] .border {
  border-color: #e4e7ea; }

[data-theme="dark"] .border-gray {
  border-color: #414348 !important; }

[data-theme="dark"] .border-light {
  border-color: #383b43 !important; }

[data-theme="dark"] .bg-light {
  background-color: #222a3a !important; }

[data-theme="dark"] .bg-white {
  background-color: #0b0e13 !important; }

[data-theme="dark"] .bg-black {
  background-color: #ddd !important; }

[data-theme="dark"] .list-group-item {
  background-color: #1a202c !important;
  border: 1px solid #444 !important;
  color: #ddd; }

[data-theme="dark"] .card {
  background-color: #1a202c !important;
  border: 1px solid #444 !important;
  color: #ddd; }

[data-theme="dark"] .card-header {
  background-color: #1a202c !important;
  border-bottom: 1px solid #444 !important; }

[data-theme="dark"] .card-footer {
  background-color: #1a202c !important;
  border-top: 1px solid #444 !important; }

[data-theme="dark"] .jumbotron {
  background-color: #1a202c !important; }

[data-theme="dark"] .app-footer {
  background-color: #1a202c !important; }

[data-theme="dark"] .app-header {
  background-color: #1a202c !important; }

[data-theme="dark"] .modal-backdrop.show {
  opacity: 0.8; }

[data-theme="dark"] .modal-content {
  background-color: #1a202c !important;
  border: 1px solid #444 !important; }

[data-theme="dark"] .modal-header {
  border-bottom: 1px solid #444 !important; }

[data-theme="dark"] .modal-footer {
  border-top: 1px solid #444 !important; }

[data-theme="dark"] .panel {
  background-color: #1a202c; }

[data-theme="dark"] .panel-body {
  background-color: #1a202c !important; }

[data-theme="dark"] .panel-heading {
  border-bottom: 1px solid transparent; }

[data-theme="dark"] .panel-heading > .dropdown .dropdown-toggle {
  color: inherit; }

[data-theme="dark"] .panel-title {
  color: inherit; }

[data-theme="dark"] .panel-footer {
  background-color: #464545;
  border-top: 1px solid #464545; }

[data-theme="dark"] .panel-default {
  border-color: #464545; }

[data-theme="dark"] .panel-default > .panel-heading {
  color: #eee;
  background-color: #1a202c;
  border-color: #464545; }

[data-theme="dark"] .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #464545; }

[data-theme="dark"] .panel-default > .panel-heading .badge {
  color: #1a202c;
  background-color: #eee; }

[data-theme="dark"] .panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #464545; }

[data-theme="dark"] .invalid-feedback {
  color: #cf534a; }

[data-theme="dark"] .alert h4 {
  color: inherit; }

[data-theme="dark"] .alert .alert-link {
  font-weight: bold; }

[data-theme="dark"] .alert-dismissable .close,
[data-theme="dark"] .alert-dismissible .close {
  color: inherit; }

[data-theme="dark"] .alert-success {
  color: #7dd19a;
  background-color: #006523;
  border-color: #007529; }

[data-theme="dark"] .alert-success hr {
  border-top-color: #3a8242; }

[data-theme="dark"] .alert-success .alert-link {
  color: #e6e6e6; }

[data-theme="dark"] .alert-success .btn-link {
  color: #e6e6e6; }

[data-theme="dark"] .alert-info {
  color: #eee;
  background-color: #3498db;
  border-color: #3498db; }

[data-theme="dark"] .alert-info hr {
  border-top-color: #258cd1; }

[data-theme="dark"] .alert-info .alert-link {
  color: #e6e6e6; }

[data-theme="dark"] .alert-warning {
  color: #eee;
  background-color: #a98d37;
  border-color: #a98d37; }

[data-theme="dark"] .alert-warning hr {
  border-top-color: #e08e0b; }

[data-theme="dark"] .alert-warning .alert-link {
  color: #e6e6e6; }

[data-theme="dark"] .alert-danger {
  color: #ffbdbd;
  background-color: #cf534a;
  border-color: #cf534a; }

[data-theme="dark"] .alert-danger hr {
  border-top-color: #cf534a; }

[data-theme="dark"] .alert-danger .alert-link {
  color: #e6e6e6; }

[data-theme="dark"] .alert-danger .btn-link {
  color: #e6e6e6; }

[data-theme="dark"] .well {
  background-color: #1a202c;
  border: 1px solid transparent; }

[data-theme="dark"] .well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15); }

[data-theme="dark"] table {
  background-color: transparent; }

[data-theme="dark"] caption {
  color: #999999; }

[data-theme="dark"] .table {
  color: #ddd; }

[data-theme="dark"] .table > thead > tr > th,
[data-theme="dark"] .table > tbody > tr > th,
[data-theme="dark"] .table > tfoot > tr > th,
[data-theme="dark"] .table > thead > tr > td,
[data-theme="dark"] .table > tbody > tr > td,
[data-theme="dark"] .table > tfoot > tr > td {
  border-top: 1px solid #464545; }

[data-theme="dark"] .table > thead > tr > th {
  border-bottom: 2px solid #464545; }

[data-theme="dark"] .table > tbody + tbody {
  border-top: 2px solid #464545; }

[data-theme="dark"] .table .table {
  background-color: #222222; }

[data-theme="dark"] .table-bordered {
  border: 1px solid #464545; }

[data-theme="dark"] .table-bordered > thead > tr > th,
[data-theme="dark"] .table-bordered > tbody > tr > th,
[data-theme="dark"] .table-bordered > tfoot > tr > th,
[data-theme="dark"] .table-bordered > thead > tr > td,
[data-theme="dark"] .table-bordered > tbody > tr > td,
[data-theme="dark"] .table-bordered > tfoot > tr > td {
  border: 1px solid #464545; }

[data-theme="dark"] .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #3d3d3d; }

[data-theme="dark"] .table-hover > tbody > tr:hover {
  background-color: #464545; }

[data-theme="dark"] .table > thead > tr > td.active,
[data-theme="dark"] .table > tbody > tr > td.active,
[data-theme="dark"] .table > tfoot > tr > td.active,
[data-theme="dark"] .table > thead > tr > th.active,
[data-theme="dark"] .table > tbody > tr > th.active,
[data-theme="dark"] .table > tfoot > tr > th.active,
[data-theme="dark"] .table > thead > tr.active > td,
[data-theme="dark"] .table > tbody > tr.active > td,
[data-theme="dark"] .table > tfoot > tr.active > td,
[data-theme="dark"] .table > thead > tr.active > th,
[data-theme="dark"] .table > tbody > tr.active > th,
[data-theme="dark"] .table > tfoot > tr.active > th {
  background-color: #464545; }

[data-theme="dark"] .table-hover > tbody > tr > td.active:hover,
[data-theme="dark"] .table-hover > tbody > tr > th.active:hover,
[data-theme="dark"] .table-hover > tbody > tr.active:hover > td,
[data-theme="dark"] .table-hover > tbody > tr:hover > .active,
[data-theme="dark"] .table-hover > tbody > tr.active:hover > th {
  background-color: #393838; }

[data-theme="dark"] .table > thead > tr > td.success,
[data-theme="dark"] .table > tbody > tr > td.success,
[data-theme="dark"] .table > tfoot > tr > td.success,
[data-theme="dark"] .table > thead > tr > th.success,
[data-theme="dark"] .table > tbody > tr > th.success,
[data-theme="dark"] .table > tfoot > tr > th.success,
[data-theme="dark"] .table > thead > tr.success > td,
[data-theme="dark"] .table > tbody > tr.success > td,
[data-theme="dark"] .table > tfoot > tr.success > td,
[data-theme="dark"] .table > thead > tr.success > th,
[data-theme="dark"] .table > tbody > tr.success > th,
[data-theme="dark"] .table > tfoot > tr.success > th {
  background-color: #4DAA57; }

[data-theme="dark"] .table-hover > tbody > tr > td.success:hover,
[data-theme="dark"] .table-hover > tbody > tr > th.success:hover,
[data-theme="dark"] .table-hover > tbody > tr.success:hover > td,
[data-theme="dark"] .table-hover > tbody > tr:hover > .success,
[data-theme="dark"] .table-hover > tbody > tr.success:hover > th {
  background-color: #3a8242; }

[data-theme="dark"] .table > thead > tr > td.info,
[data-theme="dark"] .table > tbody > tr > td.info,
[data-theme="dark"] .table > tfoot > tr > td.info,
[data-theme="dark"] .table > thead > tr > th.info,
[data-theme="dark"] .table > tbody > tr > th.info,
[data-theme="dark"] .table > tfoot > tr > th.info,
[data-theme="dark"] .table > thead > tr.info > td,
[data-theme="dark"] .table > tbody > tr.info > td,
[data-theme="dark"] .table > tfoot > tr.info > td,
[data-theme="dark"] .table > thead > tr.info > th,
[data-theme="dark"] .table > tbody > tr.info > th,
[data-theme="dark"] .table > tfoot > tr.info > th {
  background-color: #3498db; }

[data-theme="dark"] .table-hover > tbody > tr > td.info:hover,
[data-theme="dark"] .table-hover > tbody > tr > th.info:hover,
[data-theme="dark"] .table-hover > tbody > tr.info:hover > td,
[data-theme="dark"] .table-hover > tbody > tr:hover > .info,
[data-theme="dark"] .table-hover > tbody > tr.info:hover > th {
  background-color: #258cd1; }

[data-theme="dark"] .table > thead > tr > td.warning,
[data-theme="dark"] .table > tbody > tr > td.warning,
[data-theme="dark"] .table > tfoot > tr > td.warning,
[data-theme="dark"] .table > thead > tr > th.warning,
[data-theme="dark"] .table > tbody > tr > th.warning,
[data-theme="dark"] .table > tfoot > tr > th.warning,
[data-theme="dark"] .table > thead > tr.warning > td,
[data-theme="dark"] .table > tbody > tr.warning > td,
[data-theme="dark"] .table > tfoot > tr.warning > td,
[data-theme="dark"] .table > thead > tr.warning > th,
[data-theme="dark"] .table > tbody > tr.warning > th,
[data-theme="dark"] .table > tfoot > tr.warning > th {
  background-color: #a98d37; }

[data-theme="dark"] .table-hover > tbody > tr > td.warning:hover,
[data-theme="dark"] .table-hover > tbody > tr > th.warning:hover,
[data-theme="dark"] .table-hover > tbody > tr.warning:hover > td,
[data-theme="dark"] .table-hover > tbody > tr:hover > .warning,
[data-theme="dark"] .table-hover > tbody > tr.warning:hover > th {
  background-color: #e08e0b; }

[data-theme="dark"] .table > thead > tr > td.danger,
[data-theme="dark"] .table > tbody > tr > td.danger,
[data-theme="dark"] .table > tfoot > tr > td.danger,
[data-theme="dark"] .table > thead > tr > th.danger,
[data-theme="dark"] .table > tbody > tr > th.danger,
[data-theme="dark"] .table > tfoot > tr > th.danger,
[data-theme="dark"] .table > thead > tr.danger > td,
[data-theme="dark"] .table > tbody > tr.danger > td,
[data-theme="dark"] .table > tfoot > tr.danger > td,
[data-theme="dark"] .table > thead > tr.danger > th,
[data-theme="dark"] .table > tbody > tr.danger > th,
[data-theme="dark"] .table > tfoot > tr.danger > th {
  background-color: #cf534a; }

[data-theme="dark"] .table-hover > tbody > tr > td.danger:hover,
[data-theme="dark"] .table-hover > tbody > tr > th.danger:hover,
[data-theme="dark"] .table-hover > tbody > tr.danger:hover > td,
[data-theme="dark"] .table-hover > tbody > tr:hover > .danger,
[data-theme="dark"] .table-hover > tbody > tr.danger:hover > th {
  background-color: #cf534a; }

[data-theme="dark"] .btn:hover,
[data-theme="dark"] .btn:focus,
[data-theme="dark"] .btn.focus {
  text-decoration: none; }

[data-theme="dark"] .btn:active,
[data-theme="dark"] .btn.active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

[data-theme="dark"] .btn.disabled,
[data-theme="dark"] .btn[disabled],
[data-theme="dark"] fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  box-shadow: none; }

[data-theme="dark"] a.btn.disabled,
[data-theme="dark"] fieldset[disabled] a.btn {
  pointer-events: none; }

[data-theme="dark"] .btn-default {
  color: #eee;
  background-color: #464545;
  border-color: #464545; }

[data-theme="dark"] .btn-default:focus,
[data-theme="dark"] .btn-default.focus {
  color: #eee;
  background-color: #2c2c2c;
  border-color: #060606; }

[data-theme="dark"] .btn-default:hover {
  color: #eee;
  background-color: #2c2c2c;
  border-color: #272727; }

[data-theme="dark"] .btn-default:active,
[data-theme="dark"] .btn-default.active,
[data-theme="dark"] .open > .dropdown-toggle.btn-default {
  color: #eee;
  background-color: #2c2c2c;
  background-image: none;
  border-color: #272727; }

[data-theme="dark"] .btn-default:active:hover,
[data-theme="dark"] .btn-default.active:hover,
[data-theme="dark"] .open > .dropdown-toggle.btn-default:hover,
[data-theme="dark"] .btn-default:active:focus,
[data-theme="dark"] .btn-default.active:focus,
[data-theme="dark"] .open > .dropdown-toggle.btn-default:focus,
[data-theme="dark"] .btn-default:active.focus,
[data-theme="dark"] .btn-default.active.focus,
[data-theme="dark"] .open > .dropdown-toggle.btn-default.focus {
  color: #eee;
  background-color: #1a1a1a;
  border-color: #060606; }

[data-theme="dark"] .btn-default.disabled:hover,
[data-theme="dark"] .btn-default[disabled]:hover,
[data-theme="dark"] fieldset[disabled] .btn-default:hover,
[data-theme="dark"] .btn-default.disabled:focus,
[data-theme="dark"] .btn-default[disabled]:focus,
[data-theme="dark"] fieldset[disabled] .btn-default:focus,
[data-theme="dark"] .btn-default.disabled.focus,
[data-theme="dark"] .btn-default[disabled].focus,
[data-theme="dark"] fieldset[disabled] .btn-default.focus {
  background-color: #464545;
  border-color: #464545; }

[data-theme="dark"] .btn-default .badge {
  color: #464545;
  background-color: #eee; }

[data-theme="dark"] .btn-primary {
  color: #eee;
  background-color: #198db6;
  border-color: #198db6; }

[data-theme="dark"] .btn-primary:focus,
[data-theme="dark"] .btn-primary.focus {
  color: #eee;
  background-color: #28415b;
  border-color: #101b26; }

[data-theme="dark"] .btn-primary:hover {
  color: #eee;
  background-color: #28415b;
  border-color: #253c54; }

[data-theme="dark"] .btn-primary:active,
[data-theme="dark"] .btn-primary.active,
[data-theme="dark"] .open > .dropdown-toggle.btn-primary {
  color: #eee;
  background-color: #28415b;
  background-image: none;
  border-color: #253c54; }

[data-theme="dark"] .btn-primary:active:hover,
[data-theme="dark"] .btn-primary.active:hover,
[data-theme="dark"] .open > .dropdown-toggle.btn-primary:hover,
[data-theme="dark"] .btn-primary:active:focus,
[data-theme="dark"] .btn-primary.active:focus,
[data-theme="dark"] .open > .dropdown-toggle.btn-primary:focus,
[data-theme="dark"] .btn-primary:active.focus,
[data-theme="dark"] .btn-primary.active.focus,
[data-theme="dark"] .open > .dropdown-toggle.btn-primary.focus {
  color: #eee;
  background-color: #1d2f43;
  border-color: #101b26; }

[data-theme="dark"] .btn-primary.disabled:hover,
[data-theme="dark"] .btn-primary[disabled]:hover,
[data-theme="dark"] fieldset[disabled] .btn-primary:hover,
[data-theme="dark"] .btn-primary.disabled:focus,
[data-theme="dark"] .btn-primary[disabled]:focus,
[data-theme="dark"] fieldset[disabled] .btn-primary:focus,
[data-theme="dark"] .btn-primary.disabled.focus,
[data-theme="dark"] .btn-primary[disabled].focus,
[data-theme="dark"] fieldset[disabled] .btn-primary.focus {
  background-color: #198db6;
  border-color: #198db6; }

[data-theme="dark"] .btn-primary .badge {
  color: #198db6;
  background-color: #eee; }

[data-theme="dark"] .btn-success {
  color: #eee;
  background-color: #4DAA57;
  border-color: #4DAA57; }

[data-theme="dark"] .btn-success:focus,
[data-theme="dark"] .btn-success.focus {
  color: #eee;
  background-color: #3a8242;
  border-color: #003d2d; }

[data-theme="dark"] .btn-success:hover {
  color: #eee;
  background-color: #3a8242;
  border-color: #007f5e; }

[data-theme="dark"] .btn-success:active,
[data-theme="dark"] .btn-success.active,
[data-theme="dark"] .open > .dropdown-toggle.btn-success {
  color: #eee;
  background-color: #3a8242;
  background-image: none;
  border-color: #007f5e; }

[data-theme="dark"] .btn-success:active:hover,
[data-theme="dark"] .btn-success.active:hover,
[data-theme="dark"] .open > .dropdown-toggle.btn-success:hover,
[data-theme="dark"] .btn-success:active:focus,
[data-theme="dark"] .btn-success.active:focus,
[data-theme="dark"] .open > .dropdown-toggle.btn-success:focus,
[data-theme="dark"] .btn-success:active.focus,
[data-theme="dark"] .btn-success.active.focus,
[data-theme="dark"] .open > .dropdown-toggle.btn-success.focus {
  color: #eee;
  background-color: #00654b;
  border-color: #003d2d; }

[data-theme="dark"] .btn-success.disabled:hover,
[data-theme="dark"] .btn-success[disabled]:hover,
[data-theme="dark"] fieldset[disabled] .btn-success:hover,
[data-theme="dark"] .btn-success.disabled:focus,
[data-theme="dark"] .btn-success[disabled]:focus,
[data-theme="dark"] fieldset[disabled] .btn-success:focus,
[data-theme="dark"] .btn-success.disabled.focus,
[data-theme="dark"] .btn-success[disabled].focus,
[data-theme="dark"] fieldset[disabled] .btn-success.focus {
  background-color: #4DAA57;
  border-color: #4DAA57; }

[data-theme="dark"] .btn-success .badge {
  color: #4DAA57;
  background-color: #eee; }

[data-theme="dark"] .btn-info {
  color: #eee;
  background-color: #3498db;
  border-color: #3498db; }

[data-theme="dark"] .btn-info:focus,
[data-theme="dark"] .btn-info.focus {
  color: #eee;
  background-color: #217dbb;
  border-color: #16527a; }

[data-theme="dark"] .btn-info:hover {
  color: #eee;
  background-color: #217dbb;
  border-color: #2077b2; }

[data-theme="dark"] .btn-info:active,
[data-theme="dark"] .btn-info.active,
[data-theme="dark"] .open > .dropdown-toggle.btn-info {
  color: #eee;
  background-color: #217dbb;
  background-image: none;
  border-color: #2077b2; }

[data-theme="dark"] .btn-info:active:hover,
[data-theme="dark"] .btn-info.active:hover,
[data-theme="dark"] .open > .dropdown-toggle.btn-info:hover,
[data-theme="dark"] .btn-info:active:focus,
[data-theme="dark"] .btn-info.active:focus,
[data-theme="dark"] .open > .dropdown-toggle.btn-info:focus,
[data-theme="dark"] .btn-info:active.focus,
[data-theme="dark"] .btn-info.active.focus,
[data-theme="dark"] .open > .dropdown-toggle.btn-info.focus {
  color: #eee;
  background-color: #1c699d;
  border-color: #16527a; }

[data-theme="dark"] .btn-info.disabled:hover,
[data-theme="dark"] .btn-info[disabled]:hover,
[data-theme="dark"] fieldset[disabled] .btn-info:hover,
[data-theme="dark"] .btn-info.disabled:focus,
[data-theme="dark"] .btn-info[disabled]:focus,
[data-theme="dark"] fieldset[disabled] .btn-info:focus,
[data-theme="dark"] .btn-info.disabled.focus,
[data-theme="dark"] .btn-info[disabled].focus,
[data-theme="dark"] fieldset[disabled] .btn-info.focus {
  background-color: #3498db;
  border-color: #3498db; }

[data-theme="dark"] .btn-info .badge {
  color: #3498db;
  background-color: #eee; }

[data-theme="dark"] .btn-warning {
  color: #eee;
  background-color: #a98d37;
  border-color: #a98d37; }

[data-theme="dark"] .btn-warning:focus,
[data-theme="dark"] .btn-warning.focus {
  color: #eee;
  background-color: #c87f0a;
  border-color: #7f5006; }

[data-theme="dark"] .btn-warning:hover {
  color: #eee;
  background-color: #c87f0a;
  border-color: #be780a; }

[data-theme="dark"] .btn-warning:active,
[data-theme="dark"] .btn-warning.active,
[data-theme="dark"] .open > .dropdown-toggle.btn-warning {
  color: #eee;
  background-color: #c87f0a;
  background-image: none;
  border-color: #be780a; }

[data-theme="dark"] .btn-warning:active:hover,
[data-theme="dark"] .btn-warning.active:hover,
[data-theme="dark"] .open > .dropdown-toggle.btn-warning:hover,
[data-theme="dark"] .btn-warning:active:focus,
[data-theme="dark"] .btn-warning.active:focus,
[data-theme="dark"] .open > .dropdown-toggle.btn-warning:focus,
[data-theme="dark"] .btn-warning:active.focus,
[data-theme="dark"] .btn-warning.active.focus,
[data-theme="dark"] .open > .dropdown-toggle.btn-warning.focus {
  color: #eee;
  background-color: #a66908;
  border-color: #7f5006; }

[data-theme="dark"] .btn-warning.disabled:hover,
[data-theme="dark"] .btn-warning[disabled]:hover,
[data-theme="dark"] fieldset[disabled] .btn-warning:hover,
[data-theme="dark"] .btn-warning.disabled:focus,
[data-theme="dark"] .btn-warning[disabled]:focus,
[data-theme="dark"] fieldset[disabled] .btn-warning:focus,
[data-theme="dark"] .btn-warning.disabled.focus,
[data-theme="dark"] .btn-warning[disabled].focus,
[data-theme="dark"] fieldset[disabled] .btn-warning.focus {
  background-color: #a98d37;
  border-color: #a98d37; }

[data-theme="dark"] .btn-warning .badge {
  color: #a98d37;
  background-color: #eee; }

[data-theme="dark"] .btn-danger {
  color: #eee;
  background-color: #cf534a;
  border-color: #cf534a; }

[data-theme="dark"] .btn-danger:focus,
[data-theme="dark"] .btn-danger.focus {
  color: #eee;
  background-color: #b1382f;
  border-color: #921e12; }

[data-theme="dark"] .btn-danger:hover {
  color: #eee;
  background-color: #b1382f;
  border-color: #cd2a19; }

[data-theme="dark"] .btn-danger:active,
[data-theme="dark"] .btn-danger.active,
[data-theme="dark"] .open > .dropdown-toggle.btn-danger {
  color: #eee;
  background-color: #b1382f;
  background-image: none;
  border-color: #cd2a19; }

[data-theme="dark"] .btn-danger:active:hover,
[data-theme="dark"] .btn-danger.active:hover,
[data-theme="dark"] .open > .dropdown-toggle.btn-danger:hover,
[data-theme="dark"] .btn-danger:active:focus,
[data-theme="dark"] .btn-danger.active:focus,
[data-theme="dark"] .open > .dropdown-toggle.btn-danger:focus,
[data-theme="dark"] .btn-danger:active.focus,
[data-theme="dark"] .btn-danger.active.focus,
[data-theme="dark"] .open > .dropdown-toggle.btn-danger.focus {
  color: #eee;
  background-color: #b62516;
  border-color: #921e12; }

[data-theme="dark"] .btn-danger.disabled:hover,
[data-theme="dark"] .btn-danger[disabled]:hover,
[data-theme="dark"] fieldset[disabled] .btn-danger:hover,
[data-theme="dark"] .btn-danger.disabled:focus,
[data-theme="dark"] .btn-danger[disabled]:focus,
[data-theme="dark"] fieldset[disabled] .btn-danger:focus,
[data-theme="dark"] .btn-danger.disabled.focus,
[data-theme="dark"] .btn-danger[disabled].focus,
[data-theme="dark"] fieldset[disabled] .btn-danger.focus {
  background-color: #cf534a;
  border-color: #cf534a; }

[data-theme="dark"] .btn-danger .badge {
  color: #cf534a;
  background-color: #eee; }

[data-theme="dark"] .btn-link {
  font-weight: 400; }

[data-theme="dark"] .btn-link,
[data-theme="dark"] .btn-link:active,
[data-theme="dark"] .btn-link.active,
[data-theme="dark"] .btn-link[disabled],
[data-theme="dark"] fieldset[disabled] .btn-link {
  background-color: transparent;
  box-shadow: none; }

[data-theme="dark"] .btn-link,
[data-theme="dark"] .btn-link:hover,
[data-theme="dark"] .btn-link:focus,
[data-theme="dark"] .btn-link:active {
  border-color: transparent; }

[data-theme="dark"] .btn-link:hover,
[data-theme="dark"] .btn-link:focus {
  text-decoration: underline;
  background-color: transparent; }

[data-theme="dark"] .btn-link[disabled]:hover,
[data-theme="dark"] fieldset[disabled] .btn-link:hover,
[data-theme="dark"] .btn-link[disabled]:focus,
[data-theme="dark"] fieldset[disabled] .btn-link:focus {
  text-decoration: none; }

[data-theme="dark"] .nav-tabs > .nav-link {
  background-color: #1a202c;
  color: #ddd;
  border-bottom: 0; }

[data-theme="dark"] .nav-tabs .nav-link.active,
[data-theme="dark"] .nav-tabs .nav-link > a:focus,
[data-theme="dark"] .nav-tabs .nav-link > a:hover {
  background-color: #1a202c !important;
  border-bottom: 0;
  border-color: #444 !important;
  color: #eee; }

.animated-hover {
  transition: background-color 150ms ease-in; }
  .animated-hover:hover {
    background-color: #f0f3f5 !important; }

.animated-border-on-hover {
  box-sizing: border-box;
  border-width: 1px;
  border-color: transparent;
  border-style: solid;
  transition: border-color 150ms ease-in; }
  .animated-border-on-hover:hover {
    border-color: #e4e7ea; }

.pointer {
  cursor: pointer; }

.not-allowed {
  cursor: not-allowed; }

.translation-wrapper {
  position: absolute;
  top: -24px;
  right: 0;
  font-size: 20px;
  box-sizing: content-box;
  cursor: pointer;
  z-index: 2; }
  .translation-wrapper:hover {
    transform: scale(1.15); }

.form-control:not(textarea) {
  height: calc(1.5em + 0.75rem + 6px) !important; }

.form-control:disabled, .form-control[readonly] {
  cursor: not-allowed; }

.scrollable {
  overflow: auto; }
  .scrollable::-webkit-scrollbar {
    width: 3px;
    height: 3px; }
  .scrollable::-webkit-scrollbar-thumb {
    background-color: #20a8d8;
    border-radius: 4px; }
    .scrollable::-webkit-scrollbar-thumb:hover {
      opacity: 0.8; }

::-webkit-scrollbar {
  width: 5px;
  height: 3px; }

::-webkit-scrollbar-thumb {
  background-color: #20a8d8;
  border-radius: 4px; }
  ::-webkit-scrollbar-thumb:hover {
    opacity: 0.8; }

@media print {
  .better-print {
    color: black !important;
    font-weight: bold; }
    .better-print .alert,
    .better-print .alert-info {
      color: black !important;
      font-weight: bold;
      border-color: black; }
    .better-print h1,
    .better-print h2,
    .better-print h3,
    .better-print h4,
    .better-print h5,
    .better-print td,
    .better-print th,
    .better-print input,
    .better-print span,
    .better-print p {
      font-weight: bold; } }

@media print {
  .remove-in-printing-view {
    display: none; } }

.DateRangePickerInput,
.SingleDatePickerInput {
  height: calc(1.5em + 0.75rem + 6px);
  line-height: calc(1.5em + 0.75rem + 1px);
  width: 100%; }
  .DateRangePickerInput .DateInput__small,
  .SingleDatePickerInput .DateInput__small {
    width: 120px; }
  .DateRangePickerInput .DateInput_input,
  .SingleDatePickerInput .DateInput_input {
    font-size: 14px; }

.section-just-to-print {
  display: none !important; }

@media print {
  .section-just-to-print {
    display: block !important; }
  body * {
    visibility: hidden; }
  .section-to-print,
  .section-to-print *,
  #section-to-print,
  #section-to-print *,
  .section-just-to-print,
  .section-just-to-print * {
    visibility: visible; }
  .section-not-printable,
  #section-not-printable {
    opacity: 0; }
    .section-not-printable .removable,
    #section-not-printable .removable {
      display: none; }
  .section-to-print,
  #section-to-print {
    position: absolute;
    left: 24px;
    top: 24px; }
  .printable {
    visibility: visible;
    display: flex !important;
    font-size: 14px;
    line-height: 18px;
    color: black; }
    .printable * {
      visibility: visible;
      color: black; } }

.printable {
  visibility: hidden;
  display: none; }

@supports (-webkit-hyphens: none) {
  .SingleDatePicker_picker {
    z-index: 1020; } }

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: .4s; }

@keyframes fadeInOpacity {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
